<template>
  <v-app>
    <div class="d-flex align-center">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>
      <div class="ml-auto mr-4 mb-4 pl-8">
        {{selected.length}} Items seleted
      </div>
      <v-chip outlined class="ml-auto mr-4 mb-4 pl-6">
        <v-radio-group dense row v-model="difficulty">
          <v-radio label="Easy" value="easy"></v-radio>
          <v-radio label="Medium" value="medium"></v-radio>
          <v-radio label="Hard" value="hard"></v-radio>
        </v-radio-group>
      </v-chip>
      <v-btn @click="saveAssessmentInstance" class="es-btn mb-4" :disabled="disableSaveButton" color="teal">
        <v-icon small class="mr-1">mdi-content-save</v-icon>
        Save
      </v-btn>
    </div>
    <v-card class='mb-6'>
      <v-data-table
        hide-default-footer
        fixed-header
        height="70vh"
        sort-by='bucket'
        :search='search'
        :headers='headers'
        :items='items'
        :header-props="{
          sortIcon: 'mdi-chevron-down',
        }"
        :must-sort='true'
        :items-per-page='10000'
        item-key="index"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-4 pt-0 pb-4">
            <v-card class="py-3" outlined v-if="item.assessmentItems && item.assessmentItems.length">
              <v-row no-gutters class="pl-4">
                <v-col class="mb-2"><strong>Created</strong></v-col>
                <v-col class="mb-2"><strong>Author</strong></v-col>
                <v-col class="mb-2"><strong>Title</strong></v-col>
                <v-spacer />
                <v-col :cols="1"><strong>Select</strong></v-col>
              </v-row>
              <v-row no-gutters class="pl-4" v-for="assessmentItem in item.assessmentItems" :key="assessmentItem.id">
                <v-col>{{ assessmentItem.created | dateString}}</v-col>
                <v-col>{{ assessmentItem.author_name }}</v-col>
                <v-col class="mr-auto">{{ assessmentItem.title }}</v-col>
                <v-spacer />
                <v-col :cols="1"><v-checkbox dense class="ma-0 pa-0" v-model="selected" :value="assessmentItem" /></v-col>
              </v-row>
            </v-card>
            <v-alert v-else text type="info" dense>No assessment items found.</v-alert>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    search: '',
    difficulty: false,
    expanded: [],
    selected: [],
  }),
  computed: {
    ...mapState('newAssessmentInstance', [
      'headers',
      'items',
    ]),
    disableSaveButton () {
      return !this.difficulty || !this.selected.length > 0
    },
  },
  beforeMount() {
    this.$store.dispatch('newAssessmentInstance/loadAssessmentItems')
  },
  methods: {
    async saveAssessmentInstance() {
      const selectedAssessmentItems = this.selected.sort((a, b) => a.bucket - b.bucket)
      const success = await this.$store.dispatch('newAssessmentInstance/saveAssessmentInstance', {
        difficulty: this.difficulty,
        selectedAssessmentItems,
      })
      if (success) {
        this.selected = []
        this.expanded = []
        this.difficulty = false
      }
    },
  },
}
</script>
<style lang='scss' scoped>
  .no-break {
    white-space: nowrap;
  }
  .es-btn {
    font-size: 12px;
    text-transform: none;
  }
  ::v-deep {
    tr {
      cursor: pointer;
    }
    .v-input--selection-controls {
      margin-top: 0px;
    }
    .text-start {
      text-transform: capitalize;
    }
    .v-application--wrap {
      min-height: auto;
    }
    .v-text-field .v-label {
      z-index: 100;
    }
    .v-data-table-header th {
      text-align: left;
      color: white !important;
      background-color: #220a5a !important;
      &:first-child {
        border-top-left-radius: 4px;
        padding-left: 34px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
      &.active,
      & .v-data-table-header__icon {
        color: #ffe300 !important;
      }
      &.sortable {
        white-space: nowrap;
      }
    }
    .v-data-table tbody tr {
      &:hover {
        background-color: white !important;
      }
      &.v-data-table__expanded__row td {
        border-bottom: none !important;
      }
      &.v-data-table__expanded__content {
        box-shadow: none;
      }
      & td {
        // padding-top: 18px !important;
        // padding-bottom: 18px !important;

        &:first-child {
          padding-left: 34px;
          background-color: inherit
        }
      }
    }
    .v-messages {
      display: none;
    }
  }
</style>
